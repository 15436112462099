<script setup lang="ts">
const appConfig = useAppConfig();

const h1 = createTag(`Welcome to ${appConfig.dealerShort}`, 'h1', {
    pageId: 11,
    dealer: appConfig.dealerShort,
});
</script>

<template>
    <div class="h1-intro">
        <CenterContent padding="1rem">
            <h1>{{ h1 }}</h1>
        </CenterContent>
    </div>
</template>

<style lang="postcss" scoped>
.h1-intro {
    background-color: var(--color-intro-bg);

    & h1 {
        font-size: clamp(1rem, 4vw, 1.25rem);
        font-weight: var(--font-weight-normal);
        color: var(--color-intro-text);
        line-height: 1;
        text-align: center;
    }
}
</style>
