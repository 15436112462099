<template>
    <div class="arrowed-border"></div>
</template>

<style lang="postcss" scoped>
.arrowed-border {
    border-top: 4px solid var(--color-accent);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top-color: var(--color-accent);
        border-left-width: 30px;
        border-right-width: 30px;
    }
}
</style>
