<script setup lang="ts">
import Flavor03Image from '@/assets/img/home/flavor03.webp';
import ChevronRight from '@base/assets/icons/chevron_right.svg?raw';

const dealersStore = useDealersStore();
</script>

<template>
    <CenterContent padding="30px 2.5rem">
        <div class="review-links">
            <div class="left">
                <HeadingText class="heading-text">
                    "I will always go to this location to get my jeep serviced.
                    Everyone is friendly and it is super fast!"
                </HeadingText>
            </div>

            <div class="right">
                <HeadingText>Reviews</HeadingText>

                <img
                    :src="Flavor03Image"
                    :alt="`${dealersStore.siteName} Reviews`"
                    width="400"
                    height="191"
                    loading="lazy"
                />

                <div class="links">
                    <BaseButton>
                        <NuxtLink :to="{ name: 'reviews' }">
                            <span><b>See</b> Reviews</span>

                            <BaseIcon
                                :icon-html="ChevronRight"
                                aria-hidden
                                class="chevron-icon"
                            />
                        </NuxtLink>
                    </BaseButton>
                </div>
            </div>
        </div>
    </CenterContent>
</template>

<style lang="postcss" scoped>
.review-links {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('@/assets/img/home/cactus03.webp');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    & .left {
        width: 100%;
        max-width: 500px;
        padding-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & .heading-text {
            padding-top: 40px;

            & :deep(.border-wrap) {
                & .main {
                    line-height: 1.2;
                    font-size: 38px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    height: 4px;
                    width: 40%;
                    background-color: var(--color-accent);
                    bottom: 110%;
                    right: 40%;
                }
            }
        }
    }

    & .right {
        width: 100%;
        max-width: 400px;

        & img {
            display: block;
            width: 100%;
            height: 100%;
            max-width: 400px;
            margin: 0 auto;
            padding: 10px 0;
        }
    }
}

.links {
    width: 250px;
    margin: 0 auto;
}

.chevron-icon {
    --base-icon-size: 26px;
    --base-icon-stroke-width: 3;
}

@media screen and (max-width: 870px) {
    .review-links {
        flex-direction: column;

        & h2 {
            text-align: center;
        }

        & .left {
            padding: 0;
            order: 2;
            max-width: 100%;

            & .heading-text {
                & :deep(.border-wrap) {
                    & .main {
                        font-size: 32px;
                    }

                    &::before {
                        right: 10%;
                    }
                }
            }
        }

        & .right {
            order: 1;
            max-width: 100%;
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 490px) {
    .review-links {
        & .left {
            & .heading-text {
                & :deep(.border-wrap) {
                    & .main {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 410px) {
    .review-links {
        & .left {
            & .heading-text {
                & :deep(.border-wrap) {
                    & .main {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}
</style>
