<script setup lang="ts">
import Assets from '@/public/marketing-assets/marketing-assets.json';

const groupedHeroAssets = groupHeros(Assets);
</script>

<template>
    <section>
        <HomeHeroSlider
            v-if="groupedHeroAssets.length"
            :slides="groupedHeroAssets"
        />
        <HomeH1Intro />
        <HomeSearchRefineDealer />
        <HomeSellOrTrade />
        <HomeWelcome />
        <HomeQualityService />
        <HomeSearchLinks />
        <HomeSocialLinks />
        <HomeReviewLinks />
    </section>
</template>
