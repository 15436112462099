<script setup lang="ts">
import Flavor01Image from '@/assets/img/home/flavor01.webp';

const dealersStore = useDealersStore();
const { $cagGoogle } = useNuxtApp();
const componentName = getCurrentInstance()?.type.__name ?? '';

const address = `${dealersStore.currentDealer.address} ${dealersStore.currentDealer.city}, ${dealersStore.currentDealer.state}`;
</script>

<template>
    <CenterContent padding="30px 2.5rem">
        <div class="our-story">
            <img
                :src="Flavor01Image"
                :alt="`Welcome to ${dealersStore.siteName}`"
                width="400"
                height="377"
                loading="lazy"
            />
            <div class="content">
                <HeadingText> About Us </HeadingText>

                <p class="intro">
                    Serving the greater
                    {{ dealersStore.currentDealer.city }} area,
                    {{ dealersStore.siteName }} is located at {{ address }}.
                    Visit us online at
                    <NuxtLink :to="{ name: 'index' }">{{
                        dealersStore.siteUrl
                    }}</NuxtLink>
                    for 24/7 access to vehicle photos, purchase and lease
                    specials, service center coupons, and online service
                    scheduling.
                </p>

                <p>
                    Stop by today, visit our online resources, or call us at
                    <a
                        :href="`tel:${dealersStore.currentDealer.phone}`"
                        @click.passive="
                            $cagGoogle.pushPhoneToDataLayer(
                                dealersStore.currentDealer.phone,
                                componentName
                            )
                        "
                        >{{ dealersStore.currentDealer.phone }}</a
                    >
                    and experience the Chapman Choice for your vehicle needs.
                </p>
            </div>
        </div>
    </CenterContent>
</template>

<style lang="postcss" scoped>
p {
    margin: 0;
}

.intro {
    margin-top: 10px;
}

.our-story {
    background-image: url('@base/assets/images/bg-mountains.webp');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    overflow: auto;

    & img {
        display: block;
        float: right;
        height: 100%;
        width: 400px;
        margin-left: 30px;
    }
}

@media screen and (max-width: 1000px) {
    .our-story {
        & img {
            width: 248px;
            margin: 20px;
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 750px) {
    .our-story {
        display: flex;
        flex-direction: column;
        background-position: left top;

        & .content {
            order: 2;
        }

        & img {
            float: none;
            width: 100%;
            max-width: 248px;
            margin: 0 auto;
            margin-bottom: 20px;
            order: 1;
        }
    }
}
</style>
