<script setup lang="ts">
import ChevronLeft from '@base/assets/icons/chevron_left.svg?raw';
import ChevronRight from '@base/assets/icons/chevron_right.svg?raw';

export interface Props {
    slides: Record<string, MarketingAssetsMarketingAssetsManagerAsset>[];
}

const props = defineProps<Props>();

const { $cagGoogle } = useNuxtApp();
const INTERVAL = 6000;
const SECOND = 1000;

const selectedSlide = ref(0);
const timer = ref(0);
const timeRemaining = ref(INTERVAL / SECOND);
const timerStatus = ref('');

const totalSlides = props.slides.length;

const timerPercentRemaining = computed(() => {
    const millisecondsRemaining = timeRemaining.value * SECOND;
    return (millisecondsRemaining / INTERVAL) * 100;
});

const timerTransitionStyles = computed(() => {
    return {
        right: `${100 - timerPercentRemaining.value}%`,
    };
});

const startTimer = () => {
    if (!timer.value) {
        window.clearInterval(timer.value);
        timer.value = 0;
        timeRemaining.value =
            timeRemaining.value >= 0 ? timeRemaining.value : INTERVAL / SECOND;
        timer.value = window.setInterval(() => {
            timeRemaining.value -= 1;
        }, SECOND);
        timerStatus.value = 'running';
    }
};

const stopTimer = () => {
    if (timer.value) {
        window.clearInterval(timer.value);
        timer.value = 0;
        timerStatus.value = 'paused';
    }
};

const goToNextSlide = () => {
    if (selectedSlide.value === totalSlides - 1) {
        selectedSlide.value = 0;
    } else {
        selectedSlide.value += 1;
    }
};

const goToPreviousSlide = () => {
    if (selectedSlide.value === 0) {
        selectedSlide.value = totalSlides - 1;
    } else {
        selectedSlide.value -= 1;
    }
};

const handleTouch = (type: string) => {
    switch (type) {
        case 'start':
            stopTimer();
            break;
        case 'swipe-left':
            goToNextSlide();
            break;
        case 'swipe-right':
            goToPreviousSlide();
            break;
        case 'end-hold':
            startTimer();
            break;
        default:
            break;
    }
};

watch(
    () => timeRemaining.value,
    (newTime) => {
        // timer ran out
        if (newTime < 0) {
            goToNextSlide();
        }
    }
);

watch(
    () => selectedSlide.value,
    () => {
        timeRemaining.value = INTERVAL / SECOND;
        startTimer();
    }
);

const slider = ref<HTMLElement | null>(null);

onMounted(() => {
    if (totalSlides > 1) {
        if (slider.value) addTouchListeners(slider.value, handleTouch);
        startTimer();
    }
});

onBeforeUnmount(() => {
    if (slider.value) removeTouchListeners(slider.value);
});

onUnmounted(() => {
    stopTimer();
});
</script>

<template>
    <div
        ref="slider"
        class="hero-slider"
        @mouseover="stopTimer"
        @mouseleave="startTimer"
        @focusin="stopTimer"
        @focusout="startTimer"
    >
        <transition name="fade" mode="out-in">
            <NuxtLink
                :key="`${props.slides[selectedSlide]['large'].id}`"
                :to="props.slides[selectedSlide]['large'].link"
                :aria-label="props.slides[selectedSlide]['large'].altText"
                :target="
                    props.slides[selectedSlide]['large'].isExternal
                        ? '_blank'
                        : undefined
                "
                :rel="
                    props.slides[selectedSlide]['large'].isExternal
                        ? 'noopener'
                        : undefined
                "
                @click="
                    $cagGoogle.pushToDataLayer({
                        category: 'Hero Slider',
                        slide: (selectedSlide + 1).toString(),
                        totalSlides: totalSlides.toString(),
                        alt: props.slides[selectedSlide]['large'].altText,
                    })
                "
            >
                <HomeHeroImage
                    :alt="props.slides[selectedSlide].altText"
                    :slide="props.slides[selectedSlide]"
                />
            </NuxtLink>
        </transition>

        <template v-if="totalSlides > 1">
            <!-- Previous slide button -->
            <transition name="slide-right">
                <button
                    v-if="timerStatus === 'paused'"
                    aria-label="previous slide"
                    type="button"
                    class="button--slide-control button--prev-slide"
                    @click="goToPreviousSlide"
                >
                    <BaseIcon
                        :icon-html="ChevronLeft"
                        aria-hidden
                        class="chevron-icon"
                    />
                </button>
            </transition>

            <!-- Next slide button -->
            <transition name="slide-left">
                <button
                    v-if="timerStatus === 'paused'"
                    aria-label="next slide"
                    type="button"
                    class="button--slide-control button--next-slide"
                    @click="goToNextSlide"
                >
                    <BaseIcon
                        :icon-html="ChevronRight"
                        aria-hidden
                        class="chevron-icon"
                    />
                </button>
            </transition>

            <div class="timer-progress-bar">
                <div :style="timerTransitionStyles" class="progress"></div>
            </div>
        </template>
    </div>
</template>

<style lang="postcss" scoped>
.hero-slider {
    position: relative;
    background-color: #222;
    overflow-x: hidden;
}

.timer-progress-bar {
    position: relative;
    height: 5px;
    width: 100%;
    background-color: #333;

    & .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: var(--color-accent);
    }
}

.button--slide-control {
    position: absolute;
    top: 50%;
    width: 4rem;
    transform: translate3d(0, -50%, 0);
    border: none;
    background-color: transparent;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    & .chevron-icon {
        --base-icon-size: 100%;
        --base-icon-stroke-width: 3;

        color: var(--color-font-light);
        filter: drop-shadow(1px 0 0 #000) drop-shadow(-1px 0 0 #000)
            drop-shadow(0 1px 0 #000) drop-shadow(0 -1px 0 #000);
    }

    &:hover {
        & .chevron-icon {
            animation-duration: 250ms;
            animation-iteration-count: 2;
            animation-timing-function: ease-in;
        }
    }

    &.button--prev-slide {
        left: 0;

        &:hover .chevron-icon {
            animation-name: bounce-left;
        }
    }

    &.button--next-slide {
        right: 0;

        &:hover .chevron-icon {
            animation-name: bounce-right;
        }
    }
}

@keyframes bounce-left {
    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-20%);
    }
}

@keyframes bounce-right {
    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(20%);
    }
}

/* the < and > buttons */
.slide-right-enter-from,
.slide-right-leave-to {
    opacity: 0;
    transform: translate(-10%, -50%);
}

.slide-left-enter-from,
.slide-left-leave-to {
    opacity: 0;
    transform: translate(10%, -50%);
}

@media screen and (max-width: 500px) {
    .button--slide-control {
        display: none;
    }
}
</style>
