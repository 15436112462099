<script setup lang="ts">
import type { FormContext } from 'vee-validate';

export interface Props {
    disabled?: boolean;
    form?: FormContext<any>;
    hideError?: boolean;
    hideLabel?: boolean;
    label?: string;
    modelValue?: FieldSegmentedControlValue;
    name: string;
    options: FormFieldOption[];
    required?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    form: undefined,
    label: '',
    modelValue: undefined,
});

const emit = defineEmits<{
    'update:modelValue': [updatedValue: FieldSegmentedControlValue];
}>();

const {
    handleChange,
    meta: fieldMeta,
    value: fieldValue,
} = useField<FieldSegmentedControlValue>(props.name, undefined, {
    form: props.form,
    standalone: props.modelValue !== undefined,
});

/** Uses modelValue prop if it exists and uses vee-validate if not. */
const modelValue = computed({
    get: () => {
        if (props.modelValue !== undefined) {
            return props.modelValue;
        } else {
            return fieldValue.value;
        }
    },
    set: (updatedValue: FieldSegmentedControlValue) => {
        if (props.modelValue === undefined) {
            handleChange(updatedValue);
        }
        emit('update:modelValue', updatedValue);
    },
});

const isInvalid = computed(() => fieldMeta.touched && !fieldMeta.valid);

const id = useId();
</script>

<template>
    <FormsFieldWrap
        :id="id"
        :hide-error="props.hideError"
        :hide-label="props.hideLabel"
        :label="props.label"
        :meta="fieldMeta"
        :name="props.name"
        :required="props.required"
    >
        <div
            role="radio-group"
            class="options-wrap"
            :class="{ invalid: isInvalid }"
        >
            <button
                v-for="(option, index) in props.options"
                :key="`${option.value}-${index}`"
                :aria-checked="modelValue === option.value ? 'true' : 'false'"
                role="radio"
                type="button"
                class="option"
                :class="{ selected: modelValue === option.value }"
                @click="modelValue = option.value"
            >
                {{ option.label }}
            </button>
        </div>
    </FormsFieldWrap>
</template>

<style lang="postcss" scoped>
.options-wrap {
    display: flex;
    margin-bottom: 20px;

    & .option {
        background: none;
        user-select: none;
        position: relative;
        padding: 0 10px;
        line-height: 44px;
        width: 33.3%;
        font-weight: var(--font-weight-bold);
        font-family: var(--font-family);
        font-size: 1rem;
        border: 1px solid var(--color-light-border);
        border-left: none;
        transition: var(--transition-base);
        cursor: pointer;
        text-align: center;

        &:first-child {
            border-left: 1px solid var(--color-light-border);
        }

        &:hover,
        &:focus {
            background-color: var(--color-light-gray);
        }

        &.selected {
            background-color: var(--color-home-search-selected);
            color: var(--color-font-light);
        }
    }

    &.invalid {
        & .option {
            border-color: var(--color-error);
            color: var(--color-error);
        }
    }
}

@media screen and (max-width: 410px) {
    .options-wrap {
        & .option {
            width: auto;
            flex: 1 0 auto;
        }
    }
}
</style>
