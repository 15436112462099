<script setup lang="ts">
const props = defineProps<{
    slide: Record<string, MarketingAssetsMarketingAssetsManagerAsset>;
}>();
const large = props.slide['large'];
const medium = props.slide['medium'];
const small = props.slide['small'];
</script>

<template>
    <picture>
        <source
            :srcset="`/marketing-assets/${large.category}/${large.order}-${large.fileName}-${large.fileHash}.webp`"
            media="(min-width: 1201px)"
            type="image/webp"
            :width="large.imageWidth"
            :height="large.imageHeight"
        />
        <source
            :srcset="`/marketing-assets/${medium.category}/${medium.order}-${medium.fileName}-${medium.fileHash}.webp`"
            media="(min-width: 801px) and (max-width: 1200px)"
            type="image/webp"
            :width="medium.imageWidth"
            :height="medium.imageHeight"
        />
        <source
            :srcset="`/marketing-assets/${small.category}/${small.order}-${small.fileName}-${small.fileHash}.webp`"
            media="(max-width: 800px)"
            type="image/webp"
            :width="small.imageWidth"
            :height="small.imageHeight"
        />
        <img
            :srcset="`/marketing-assets/${small.category}/${small.order}-${small.fileName}-${small.fileHash}.webp`"
            :alt="small.altText"
            :width="small.imageWidth ?? undefined"
            :height="small.imageHeight ?? undefined"
            fetchpriority="high"
        />
    </picture>
</template>

<style lang="postcss" scoped>
img {
    display: block;
    width: 100%;
    max-width: 1920px;
    height: auto;
    margin: 0 auto;
}
</style>
