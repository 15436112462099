<script setup lang="ts">
import Facebook from '@base/assets/icons/facebook.svg?raw';
import Instagram from '@base/assets/icons/instagram.svg?raw';
import Youtube from '@base/assets/icons/youtube.svg?raw';
import Blog from '@base/assets/icons/blog.svg?raw';

const dealersStore = useDealersStore();
</script>

<template>
    <section class="social-links">
        <ArrowedBorder />
        <CenterContent padding="30px 2.5rem">
            <HeadingText> Social Media </HeadingText>
            <div class="links">
                <a
                    v-if="dealersStore.currentDealer.facebook"
                    :href="dealersStore.currentDealer.facebook"
                    target="_blank"
                    rel="noopener"
                    title="Facebook"
                    aria-label="Facebook"
                >
                    <BaseIcon :icon-html="Facebook" class="social-icon" />
                    <span>Facebook</span>
                </a>
                <a
                    v-if="dealersStore.currentDealer.instagram"
                    :href="dealersStore.currentDealer.instagram"
                    target="_blank"
                    rel="noopener"
                    title="Instagram"
                    aria-label="Instagram"
                >
                    <BaseIcon :icon-html="Instagram" class="social-icon" />
                    <span>Instagram</span>
                </a>
                <a
                    v-if="dealersStore.currentDealer.youtube"
                    :href="dealersStore.currentDealer.youtube"
                    target="_blank"
                    rel="noopener"
                    title="YouTube"
                    aria-label="YouTube"
                >
                    <BaseIcon :icon-html="Youtube" class="social-icon" />
                    <span>YouTube</span>
                </a>
                <a
                    v-if="dealersStore.currentDealer.blog"
                    :href="dealersStore.currentDealer.blog"
                    target="_blank"
                    rel="noopener"
                    title="Blog"
                    aria-label="Blog"
                >
                    <BaseIcon :icon-html="Blog" class="social-icon" />
                    <span>Blog</span>
                </a>
            </div>
        </CenterContent>
    </section>
</template>

<style lang="postcss" scoped>
.social-links {
    color: var(--color-font-light);
    position: relative;

    &::after {
        content: '';
        background: url('@/assets/img/home/mountain-bg.webp') center center;
        background-size: cover;
        inset: 0;
        position: absolute;
        z-index: -1;
        transform: scaleX(-1);
    }
}

.links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px 20px;
        text-transform: uppercase;
        color: var(--color-font-light);
        fill: var(--color-font-light);

        &:hover,
        &:focus {
            color: var(--color-accent);
            fill: var(--color-accent);
        }

        & .social-icon {
            --base-icon-size: 50px;
            --base-icon-stroke: none;

            margin-bottom: 5px;
        }
    }
}
</style>
